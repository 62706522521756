/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1an6a0x --style4 bg--top --full --parallax" name={"wstęp"} border={""} parallax={true} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="--center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--128 w--300 ls--002 lh--1" content={"<span style='color: white'>Restauracja<br>Comida</span>"}>
              </Title>

              <Button className="btn-box btn-box--shape3" content={"Menu dnia"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pl--20 pr--20 pt--60" name={"informacje"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":514}}>
              
              <Title className="title-box" content={"Restauracja na Starym rynku w Warszawie"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":734}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/80/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/80/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/80/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/80/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/80/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/80/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1gqy15h bg--center-h --parallax pb--80 pl--20 pr--20 pt--80" name={"menu"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-1_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --shape3 --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left pl--0 pr--0" style={{"maxWidth":1525}} content={"<span style='color: white;'>Menu dnia<span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--80 pt--60" name={"menu-2"} parallax={true}>
          
          <ColumnWrap className="column__flex --shape3 --left el--2 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="--shape3">
              
              <Title className="title-box fs--48" content={"Poniedziałek 1.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Zupa:<br>Rosół z ręcznie wyrabianymi kluskami."}>
              </Text>

              <Text className="text-box" content={"1. Pieczeń huzarska, purée ziemniaczane, sałatka — 18,99 zł<br>2. Makaron z kurczakiem w sosie szpinakowo-śmietanowym — 18,99 zł<br>3. Kotlety schabowe z czosnkiem, ziemniaczki amerykańskie, sos tatarski — 18,99zł<br>4. Sałatka Cezar, dressing, pieczywo — 18,99zł"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3">
              
              <Title className="title-box fs--48" content={"Wtorek, 2.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Zupa:<br>Rosół z ręcznie wyrabianymi kluskami."}>
              </Text>

              <Text className="text-box" content={"1. Pieczeń huzarska, purée ziemniaczane, sałatka — 18,99 zł<br>2. Makaron z kurczakiem w sosie szpinakowo-śmietanowym — 18,99 zł<br>3. Kotlety schabowe z czosnkiem, ziemniaczki amerykańskie, sos tatarski — 18,99zł<br>4. Sałatka Cezar, dressing, pieczywo — 18,99zł"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20">
              
              <Title className="title-box fs--48" content={"Środa, 3.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Zupa:<br>Rosół z ręcznie wyrabianymi kluskami."}>
              </Text>

              <Text className="text-box" content={"1. Pieczeń huzarska, purée ziemniaczane, sałatka — 18,99 zł<br>2. Makaron z kurczakiem w sosie szpinakowo-śmietanowym — 18,99 zł<br>3. Kotlety schabowe z czosnkiem, ziemniaczki amerykańskie, sos tatarski — 18,99zł<br>4. Sałatka Cezar, dressing, pieczywo — 18,99zł"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20">
              
              <Title className="title-box fs--48" content={"Czwartek, 4.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Zupa:<br>Rosół z ręcznie wyrabianymi kluskami."}>
              </Text>

              <Text className="text-box" content={"1. Pieczeń huzarska, purée ziemniaczane, sałatka — 18,99 zł<br>2. Makaron z kurczakiem w sosie szpinakowo-śmietanowym — 18,99 zł<br>3. Kotlety schabowe z czosnkiem, ziemniaczki amerykańskie, sos tatarski — 18,99zł<br>4. Sałatka Cezar, dressing, pieczywo — 18,99zł"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20" style={{"maxWidth":914}}>
              
              <Title className="title-box fs--48" content={"Piątek, 5.5."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":421}} content={"Zupa:<br>Rosół z ręcznie wyrabianymi kluskami."}>
              </Text>

              <Text className="text-box" content={"1. Pieczeń huzarska, purée ziemniaczane, sałatka — 18,99 zł<br>2. Makaron z kurczakiem w sosie szpinakowo-śmietanowym — 18,99 zł<br>3. Kotlety schabowe z czosnkiem, ziemniaczki amerykańskie, sos tatarski — 18,99zł<br>4. Sałatka Cezar, dressing, pieczywo — 18,99zł"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape3 mt--20" style={{"maxWidth":914}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1qrryh0 bg--center-h --parallax pb--80 pl--20 pr--20 pt--80" name={"informace"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/80/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --shape3 el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center lh--14" content={"<span style='color: white;'>Pańska 18, Warszawa<br>info@twoje-stronyy.com<br>+48 797 811 2X0<span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex el--2 pl--0 pr--0 flex--top" columns={"2"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Title className="title-box fs--48" content={"Comida"}>
              </Title>

              <Text className="text-box mt--20" style={{"maxWidth":437}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--right" content={"ND — CZW"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--right mt--02" style={{"color":"var(--color-variable-1)"}} content={"9:00 — 23:00"}>
              </Subtitle>

              <Text className="text-box text-box--right" content={"PT — SOB"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--right mt--02" style={{"color":"var(--color-variable-1)"}} content={"11:00 — 02:00"}>
              </Subtitle>

              <Text className="text-box text-box--right" content={"<a href=\"https://saywebpage.com\">Tworzenie stron</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}